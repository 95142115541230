
import React from 'react'

import cn from 'classnames';
import { useRouter } from 'next/router';

import { COMMUNITY_PATHS } from '@constants/index';

import styles from './index.module.css'

const Item = (props) => {
  const { item, key } = props;
  const router = useRouter();

  return (
    <div
      className={styles.hotTopicList}
      onClick={() => router.push(COMMUNITY_PATHS.TOPIC + item.id)}
      key={key}
      data-buried="topic"
      data-buried-type="hot-topic"
    >
      <span className={cn('DINPro-Medium')}>{`#${item.content}#`}</span>
    </div>
  )
};
export default Item
