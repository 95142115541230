import React, { useRef, useState } from 'react';
import cn from 'classnames';
import I18n from '@utils/i18n';
import { useIsomorphicLayoutEffect } from '@lib/hooks';
import styles from './index.module.less';

// options 投票数据
// agree 左侧投票百分比
// oppose 右侧投票百分比
// borderRadiusArr 投票进度是否需要圆角
// applyAnm 是否应用动画
// show 是否需要投票双方左右分隔
// dynamicLeft
const minWidth = 30;

const getVoteWidth = ({ agree, oppose, totalWidth }) => {
  let agreetWidth = totalWidth * (agree / 100) + 20;
  let opposetWidth = totalWidth * (oppose / 100) + 20;
  if (agreetWidth < minWidth && agreetWidth > 0) {
    opposetWidth = opposetWidth - (minWidth - agreetWidth);
    agreetWidth = minWidth
  }
  if (opposetWidth < minWidth && opposetWidth > 0) {
    agreetWidth = agreetWidth - (minWidth - opposetWidth);
    opposetWidth = minWidth;
  }
  agreetWidth = !agree ? 0 : agreetWidth;
  opposetWidth = !oppose ? 0 : opposetWidth;
  return {
    agreetWidth, opposetWidth
  }
}

export const VoterDetial = (props) => {
  const { square, opposition, agree, oppose } = props;
  const ref = useRef();
  const [applyAnm, setApplyAnm] = useState(false);
  useIsomorphicLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      const { contentBoxSize: [{ inlineSize }] } = entry;
      const { agreetWidth, opposetWidth } = getVoteWidth({ agree, oppose, totalWidth: inlineSize })
      ref.current?.style?.setProperty?.('--voter-left', `${agreetWidth}px`)
      ref.current?.style?.setProperty?.('--voter-right', `${opposetWidth}px`)
      setApplyAnm(true)
    });
    resizeObserver.observe(ref.current);
    return () => {
      resizeObserver.unobserve(ref.current)
    }
  }, [agree, oppose])

  useIsomorphicLayoutEffect(() => {
    if (!oppose) {
      ref.current?.style?.setProperty?.('--position-left', '-5px')
    }
    if (!agree) {
      ref.current?.style?.setProperty?.('--position-right', '-5px')
    }
  }, [agree, oppose])

  return (
    <div className={styles.voterDetial}>
      <div className={styles.voterName}>
        <div className={styles.voterNameLeft}>{square?.like ? I18n.t('community_select') : ''} {square?.text}</div>
        <div className={styles.voterNameRight}>{opposition?.like ? I18n.t('community_select') : ''} {opposition?.text}</div>
      </div>
      <div className={styles.percentCont}>
        <div className={cn(styles.percentDataleft, 'DINPro')}>{agree}%</div>
        <div className={styles.percentNum} ref={ref}>
          <div className={cn(styles.left, {
            [styles.pollbackLeftAnm]: applyAnm
          })}></div>
          <div className={cn(styles.right, {
            [styles.pollbackRightAnm]: applyAnm
          })}></div>
        </div>
        <div className={cn(styles.percentDataright, 'DINPro')}>{oppose}%</div>
      </div>
    </div>
  )
}
