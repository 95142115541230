import React, { Fragment } from 'react';
import I18n from '@utils/i18n';
import { useVote } from './useVote'
import styles from './index.module.less';
export const VoteWrapper = (props) => {
  const { topicDetail, voterCount, children } = props;
  const { viewCount } = topicDetail;
  const { display, ...rest } = useVote(topicDetail)
  if (!display) {
    return null
  }
  return (
    <Fragment>
      <div className={styles.voterRules}>
        <div className={styles.viewNum}>
          <span>{I18n.t('community_viewers')}</span>
          <span className={'DINPro'}> {viewCount}</span>
        </div>
        <div className={styles.voterNum}>
          <span>{I18n.t('community_voters')}</span>
          <span className={'DINPro'}> {voterCount}</span>
        </div>
      </div>
      <div className={styles.voteWrapper}>
        {children(rest)}
      </div>
    </Fragment>
  )
}
