
import React, { useState } from 'react'
import styles from './index.module.css'
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import { getRemoteConfigValue } from '@utils/firebase'
import Item from './Item';

const HotTopic = (props) => {
  const { hotTopicData = [] } = props;
  const [tagNumConfig, setTagNumConfig] = useState(8)
  const [topicData, setTopicData] = useState({})

  useIsomorphicLayoutEffect(() => {
    if (hotTopicData.length === 0) return;

    getRemoteConfigValue('discover').then((res) => {
      const discoverConfig = JSON.parse(res.asString()) || {}
      setTagNumConfig(discoverConfig.tagNum ?? 8)
    })
    // 显示顺序从上到下从左到右，保证热度高的topic能在首评展示
    const row1 = [];
    const row2 = [];
    const row3 = [];
    hotTopicData.forEach((item, index) => {
      switch (index % 3) {
        case 0:
          row1.push(item)
          break;
        case 1:
          row2.push(item)
          break;
        case 2:
          row3.push(item)
          break;
      }
    })
    setTopicData({
      row1: row1.slice(0, tagNumConfig ?? 8),
      row2: row2.slice(0, tagNumConfig ?? 8),
      row3: row3.slice(0, tagNumConfig ?? 8)
    })
  }, [hotTopicData, tagNumConfig])

  const renderRow = () => {
    const resDom = Object.keys(topicData).map(row => {
      return (
        <div className={styles.h5Row} key={row} >
          {
            topicData[row].map(item => <Item item={item} key={item.id} />)
          }
        </div>
      )
    })
    return resDom
  }

  return (
    <div className={styles.h5Container}>
      <div className={styles.h5List}>
        {renderRow()}
      </div>
    </div>
  )
};
export default HotTopic
