import React, { Fragment } from 'react';
import cn from 'classnames';
import I18n from '@utils/i18n'
import styles from './index.module.less';

export const TopicBannerInfo = (props) => {
  const { backgroundUrl, content, viewCount, desc } = props;
  return (
    <Fragment>
      <div className={styles.hotdetial}>
        <img src={backgroundUrl} className={styles.detialPic} />
        <div className={styles.detialTitle}>
          <h1 className={cn(styles.hotTopiccontent, 'DINPro-Medium')}>#{content}</h1>
          <div className={styles.viewCount}>
            <span>{I18n.t('community_viewers')}</span>
            <span className='DINPro'> {viewCount}</span>
          </div>
        </div>
      </div>
      <p className={styles.topicDesc}>
        {desc}
      </p>
    </Fragment>
  )
}
