
import React, { useCallback, useState } from 'react'

import cn from 'classnames';
import { inject, observer } from 'mobx-react'

import { SocialAPI } from '@api/social';
import AvatarComponent from '@components/AvatarComponent';
import { Pagination } from '@element/index';
import useClient from '@lib/hooks/useClient';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import pubsub from '@lib/scripts/pubsub'
import helper from '@utils/helper';
import I18n from '@utils/i18n';
import { FollowBtn } from '@widget/community'

// import styles from '@styles/modules/communityTopic.module.css'
import styles from './index.module.less';

const LIMITNUM = 6
const LIMIT = 9999
const HotPerson = (props) => {
  const ispc = useClient()
  const { newLimit, isLimit, btnClassName, titleClass, className, followBtnClass } = props
  const whichOne = isLimit ? newLimit : LIMITNUM
  const [offset, setOffset] = useState(0)
  const [fetchEntrusting, setFetchEntrusting] = useState(false)
  // 初始化当前页面的数据
  const [hotUserData, setHotUserData] = useState([])
  // 对接口返回的所有数据进行缓存，无论前一页还是下一页，始终使用该数据
  const [storageHotUserData, setStorageHotUserData] = useState([])
  const [hasNextHistory, setHasNextHistory] = useState(false)
  const [hasPrevHistory, setHasPrevHistory] = useState(false)
  const token = props.userStore.userInfo.jwtKey
  const getUserData = async () => {
    const params = {
      limit: LIMIT,
      language: 'zh'
    }

    setFetchEntrusting(true)
    const res = await SocialAPI.getHotUsers(params, { Authorization: token })
    console.log('11111', res);
    if (res && res.result) {
      const hotPersonData = res.result.res
      setHotUserData(hotPersonData)
      // 获取接口所有数据
      setStorageHotUserData(hotPersonData)
      setFetchEntrusting(false)
      const isTrue = isLimit
        ? hotPersonData.slice(0, 11).length === newLimit
        : hotPersonData.slice(0, 6).length === LIMITNUM
      setHasNextHistory(isTrue)
      setHasPrevHistory(offset !== 0)
    }
  }
  useIsomorphicLayoutEffect(() => {
    getUserData()
  }, [])

  const followChange = (params) => {
    const newData = storageHotUserData.map(item => {
      if (params.toCustomerId === item?.customerId) {
        item.isFollow = params.isFollow
      }
      return item
    })
    setStorageHotUserData(newData)
  }

  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('FOLLOW_CHANGE', followChange)
    return () => {
      pubsub.unSubscribe('FOLLOW_CHANGE', followChange);
    }
  }, [storageHotUserData])

  // 右边跳转按钮
  const toNextPage = useCallback(() => {
    // 获取当前跳转到第几页
    setOffset(offset + whichOne)
    // 截取当前页后所有的值，storageHotUserData所有数据
    const newHotData = storageHotUserData.slice(offset + whichOne, LIMIT)

    // 判断最后一页数据是否小于等于whichOne条数据，确定下一页和上一页按钮是否不可点击
    setHasNextHistory(!(newHotData.length <= whichOne))
    setHasPrevHistory(offset + whichOne !== 0)
    setHotUserData(newHotData)
  }, [offset, fetchEntrusting])

  // 左边跳转按钮
  const toPrevPage = useCallback(() => {
    setOffset(offset - whichOne)
    // 截取当前页之前的所有的值，storageHotUserData所有数据
    const newstorageHotUserData = storageHotUserData.slice(offset - whichOne, LIMIT)
    // 判断每页是否有六条数据，确定下一页和上一页按钮是否不可点击
    const isTrue = isLimit
      ? newstorageHotUserData.slice(0, 11).length === newLimit
      : newstorageHotUserData.slice(0, 6).length === LIMITNUM
    setHasNextHistory(isTrue)
    // setHasNextHistory(newstorageHotUserData.slice(0, 6).length === LIMITNUM)
    setHasPrevHistory(offset - whichOne !== 0)

    setHotUserData(newstorageHotUserData)
  }, [offset, fetchEntrusting])

  return (
        <div className={cn(styles.hotPeople, className)} data-name="hot-people">
            <div className={styles.hotPeoplePage}>
                <h2 className={cn(styles.hotPersonTitle, titleClass, 'Bold')}>{I18n.t('kikiStars')}</h2>
                {
                  ispc
                    ? <Pagination
                      className={cn(styles.pagination, btnClassName)}
                      preDisable={!hasPrevHistory}
                      nextDisable={!hasNextHistory}
                      clickPre={toPrevPage}
                      clickNext={toNextPage}
                  />
                    : ''
                }

            </div>
            <div className={styles.hotPerson}>
                {
                    hotUserData.slice(0, whichOne).map(item => (
                        <div key={item.customerId} className={styles.hotPersonCont}>
                            <AvatarComponent
                              avatarSize={ispc ? 40 : 60}
                              avatar={item.avatar}
                              customerId={item?.customerId}
                              identificationSource={helper.identificationSwitch(item?.role)}
                              identificationSize={14}
                              />
                            <div className={styles.hotPersonDescribe}>
                                <div className={cn(styles.hotPersonName, 'DINPro-Medium')}>{item.nickName}</div>
                                <div className={cn(styles.describe, 'DINPro-Medium')}>
                                  {item.intro ? item.intro : I18n.t('no_intro')}
                                </div>
                            </div>
                            <div className={styles.followBtn}>
                              <FollowBtn
                                className={cn(styles.btn, followBtnClass)}
                                type="ghost"
                                size="lg"
                                isFollow={item.isFollow}
                                toCustomerId={item.customerId}
                              />
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
  )
};
export default inject(rootStore => ({
  userStore: rootStore.store.userStore
}))(observer(HotPerson))
