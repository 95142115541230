import React, { useRef } from 'react';
import cn from 'classnames';
import { useRouter } from 'next/router'
import { I18n, getLoginPath, handleMaskScroll } from '@utils/index';
import { useAuthSession, useIsomorphicLayoutEffect } from '@lib/hooks'
import styles from './index.module.less';

export const PostEntryBtn = ({ className, topic = {} }) => {
  const isLogin = useAuthSession({ redirectTo: null });
  const router = useRouter();
  const mainRef = useRef();
  const jumpPage = (type) => {
    if (!isLogin) {
      router.push(getLoginPath())
      return
    }
    router.push({
      pathname: `/publish/${type}`
    })
    sessionStorage.setItem('editor_topic', JSON.stringify(topic))
  }
  const onClick = (e) => {
    e.stopPropagation()
    const clientWidth = document.body.clientWidth;
    if (clientWidth > 960) return;
    mainRef.current.classList.add(styles.h5Container);
    document.body.classList.add(styles.bodyOverflow);
    handleMaskScroll(true)
  }
  const onClose = (e) => {
    const clientWidth = document.body.clientWidth;
    if (clientWidth > 960) return;
    mainRef.current.classList.remove(styles.h5Container);
    handleMaskScroll(false)
    document.body.classList.remove(styles.bodyOverflow);
    return false
  }
  const onCloseBtn = (e) => {
    e.stopPropagation()
  }
  useIsomorphicLayoutEffect(() => {
    const resizeObserver = new ResizeObserver(([entry]) => {
      const { contentBoxSize: [{ inlineSize }] } = entry;
      if (inlineSize > 960) {
        mainRef.current.classList.remove(styles.h5Container);
        handleMaskScroll(false);
        document.body.classList.remove(styles.bodyOverflow);
      }
    });
    resizeObserver.observe(document.body);
    return () => {
      resizeObserver.unobserve(document.body)
      handleMaskScroll(false);
      document.body.classList.remove(styles.bodyOverflow);
    }
  }, [])
  return (
    <div ref={mainRef} className={cn(styles.container, className)} onClick={onClose}>
      <div className={styles.main} onClick={onCloseBtn}>
        <span className={cn('icon-modal-close', styles.h5Close)} onClick={onClose} />
        <div className={styles.pen} onClick={onClick} />
        <div className={cn(styles.btn, styles.penBlack)} onClick={() => jumpPage('post')}>{I18n.t('pubslishPost')}</div>
        <div className={styles.btn} onClick={() => jumpPage('text')}>{I18n.t('pubslishLongLext')}</div>
      </div>
    </div>
  )
}
