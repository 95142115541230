
import React, { useCallback, useState } from 'react'

import cn from 'classnames';

import { SocialAPI } from '@api/social';
import { Pagination } from '@element/index';
import useClient from '@lib/hooks/useClient';
import { useIsomorphicLayoutEffect } from '@lib/hooks/useIsomorphicLayoutEffect'
import pubsub from '@lib/scripts/pubsub';
import I18n from '@utils/i18n';

import H5List from './H5List';
import Item from './Item';

import styles from './index.module.css'

const PageSize = 7
const LIMIT = 100
const HotTopic = (props) => {
  const ispc = useClient()
  const { topicPageSize, isLimit, className, titleClassName, btnClassName } = props
  const limitCount = isLimit ? topicPageSize : PageSize
  const [offset, setOffset] = useState(0)
  const [fetchEntrusting, setFetchEntrusting] = useState(false)
  // 当前展示的数据
  const [hotTopicData, setHotTopicData] = useState([])
  // 所有数据
  const [storageHotUserData, setStorageHotUserData] = useState([])
  const [hasNextHistory, setHasNextHistory] = useState(false)
  const [hasPrevHistory, setHasPrevHistory] = useState(false)

  const getTopicData = async () => {
    const params = {
      limit: LIMIT
    }
    setFetchEntrusting(true)
    const res = await SocialAPI.getHotTopic(params)
    if (res && res.result && res.result.res) {
      const topicData = res.result.res
      setHotTopicData(topicData)
      // 获取接口所有数据
      setStorageHotUserData(topicData)
      setFetchEntrusting(false)
      const istrue = isLimit
        ? topicData.slice(0, topicPageSize).length === topicPageSize : topicData.slice(0, PageSize).length === PageSize
      setHasNextHistory(istrue)
      setHasPrevHistory(offset !== 0)
    }
  }

  useIsomorphicLayoutEffect(() => {
    getTopicData()
  }, [])
  useIsomorphicLayoutEffect(() => {
    pubsub.subscribe('UPDATE_SOCIAL_FEED', getTopicData);
    return () => {
      pubsub.unSubscribe('UPDATE_SOCIAL_FEED', getTopicData);
    };
  }, []);
  // 右边跳转按钮
  const toNextPage = useCallback(() => {
    // 获取当前跳转到第几页
    setOffset(offset + limitCount)
    // 截取当前页后所有的值，storageHotUserData所有数据
    const newHotData = storageHotUserData.slice(offset + limitCount, LIMIT)
    // 判断每页是否有六条数据，确定下一页和上一页按钮是否不可点击
    setHasNextHistory(!(newHotData.length <= limitCount))
    setHasPrevHistory(offset + limitCount !== 0)
    setHotTopicData(newHotData)
  }, [offset, fetchEntrusting])

  // 左边跳转按钮
  const toPrevPage = useCallback(() => {
    setOffset(offset - limitCount)
    // 截取当前页之前的所有的值，storageHotUserData所有数据
    const newstorageHotUserData = storageHotUserData.slice(offset - limitCount, LIMIT)
    // 判断每页是否有六条数据，确定下一页和上一页按钮是否不可点击
    const istrue = isLimit ? newstorageHotUserData.slice(0, topicPageSize).length === topicPageSize
      : newstorageHotUserData.slice(0, PageSize).length === PageSize
    setHasNextHistory(istrue)
    setHasPrevHistory(offset - limitCount !== 0)

    setHotTopicData(newstorageHotUserData)
  }, [offset, fetchEntrusting])

  return (
    <div className={cn(styles.hotTopicCont, className)} data-name="hot-topic">
      <div className={styles.hotPeoplePage}>
        <h2 className={cn(styles.hotTopicTitle, titleClassName, 'Bold')}>{I18n.t('community_hotPost')}</h2>
        {
          ispc
            ? <Pagination
              className={cn(styles.pagination, btnClassName)}
              preDisable={!hasPrevHistory}
              nextDisable={!hasNextHistory}
              clickPre={toPrevPage}
              clickNext={toNextPage}
          />
            : ''
        }
      </div>
      {
        ispc
          ? (<div className={styles.hotTopicDetial} data-name="topic-item">
              {
                hotTopicData.slice(0, limitCount).map(item => (
                  <Item
                    item={item}
                    key={item.id}
                  />
                ))
              }
            </div>)
          : (
            <H5List
              hotTopicData={storageHotUserData}
            />
            )
      }
    </div>
  )
};

export default HotTopic;
