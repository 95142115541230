import { useMemo } from 'react';
export const useVote = (topicDetail) => {
  const { id, pollEntry, pollViewId } = topicDetail
  const { pollOptions } = pollEntry || {};
  const [square, opposition] = pollOptions || []; // 投票正反方
  const { isSelected, oppose, agree, borderRadiusArr } = useMemo(() => {
    const total = square?.likeCount + opposition?.likeCount;
    const agree = total ? Math.round((square.likeCount / total) * 100) : 50;
    const oppose = total ? 100 - agree : 50;
    const borderRadiusArr = [false, false]
    if (square?.likeCount === 0) {
      borderRadiusArr[1] = true;
    } else {
      borderRadiusArr[0] = true;
    }
    if (opposition?.likeCount === 0) {
      borderRadiusArr[0] = true;
    } else {
      borderRadiusArr[1] = true;
    }
    return {
      isSelected: square?.like || opposition?.like,
      borderRadiusArr,
      agree,
      oppose
    }
  }, [id, square, opposition])

  return {
    display: square && opposition && pollViewId,
    isSelected,
    displayInterval: square?.likeCount && opposition?.likeCount, // 正反双方都有人支持的话就显示投票中间的间隔
    square,
    opposition,
    oppose,
    agree,
    borderRadiusArr
  }
}
