import React from 'react';
import styles from './index.module.less';

export const VoteTodo = (props) => {
  const { square, opposition, handleVoteClick } = props;
  if (!square || !opposition) {
    return null;
  }
  return (
    <div className={styles.voterChoice}>
      <div className={styles.voterChoiceLeft} onClick={() => handleVoteClick(square)}>
        <div className={styles.pollText}>
          {square.text}
        </div>
      </div>
      <div className={styles.voterChoiceMiddle}>
      </div>
      <div className={styles.voterChoiceRight} onClick={() => handleVoteClick(opposition)}>
        <div className={styles.pollText}>
          {opposition.text}
        </div>
      </div>
    </div>
  )
}
